import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import dateFormat from 'dateformat'
import { PageLink, NewsletterSubscriptionForm } from '../..'
import { l, t } from '../../../lib/locale'
import { page } from '../../../lib/page'
import VisaLogo from './visalogo'
import MastercardLogo from './mastercardlogo'
import PaypalLogo from './paypallogo'
import EmotaLogo from './emotalogo'
import SafeshopLogo from './safeshoplogo'
import SafeshopNomineeLogo from './nomineelogo'

const app = require('../../../../package.json')

const PageFooter = ({ lang }) => {
  return (
    <StaticQuery
      query={graphql`
        query PageFooterQuery {
          shopCountry(id_country: {eq: 3}) {
            name_en
            name_nl
          }
        }
      `}
      render={({ shopCountry }) => (
        <section className="footer text-center">
          <div className="container">

            <div className="row footer-row">
              <div className="col-md-3 footer-column">
                <h4>{l('Information', lang)}</h4>
                <PageLink className="d-block" to={t(page('delivery'), 'path', lang)}>
                  {t(page('delivery'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('returnpolicy'), 'path', lang)}>
                  {t(page('returnpolicy'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('payment'), 'path', lang)}>
                  {t(page('payment'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('about'), 'path', lang)}>
                  {t(page('about'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('meet-the-team'), 'path', lang)}>
                  {t(page('meet-the-team'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('terms'), 'path', lang)}>
                  {t(page('terms'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('privacy'), 'path', lang)}>
                  {t(page('privacy'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('complaints'), 'path', lang)}>
                  {t(page('complaints'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('vacatures'), 'path', lang)}>
                  {t(page('vacatures'), 'title', lang)}
                </PageLink>
              </div>

              <div className="col-md-3 footer-column">
                <h4>{l('Contact', lang)}</h4>
                <p><a href={`mailto: ${process.env.GATSBY_EMAIL}`}>{process.env.GATSBY_EMAIL}</a></p>
                <p><a href={`tel:${process.env.GATSBY_PHONE}`}>{process.env.GATSBY_PHONE}</a></p>
                {process.env.GATSBY_CONTACT_FORM && (
                  <PageLink className="d-block" to={t(page('contact'), 'path', lang)}>
                    {l('link-contact-form', lang)}
                  </PageLink>
                )}

                <h4 className="mt-5">{l('Store invoice address', lang)}</h4>
                <p>{process.env.GATSNY_COMPANY_LEGALNAME}</p>
                <p>{process.env.GATSNY_COMPANY_ADDRESS} {process.env.GATSNY_COMPANY_ADDRESSNR}</p>
                <p>{process.env.GATSNY_COMPANY_POSTCODE} {process.env.GATSNY_COMPANY_CITY} {shopCountry[`name_${lang.suffix}`]}</p>
                <p>BTW: {process.env.GATSNY_COMPANY_VATNR}</p>
                <p>IBAN: {process.env.GATSNY_COMPANY_BANKACCOUNT}</p>
              </div>

              <div className="col-md-3 footer-column">
                <h4>{l('Store location', lang)}</h4>
                <p>{process.env.GATSBY_SITENAME}</p>
                <p>{process.env.GATSNY_COMPANY_ADDRESS} {process.env.GATSNY_COMPANY_ADDRESSNR}</p>
                <p>{process.env.GATSNY_COMPANY_POSTCODE} {process.env.GATSNY_COMPANY_CITY} {shopCountry[`name_${lang.suffix}`]}</p>

                <h4 className="mt-3">{l('Opening hours', lang)}</h4>
                <p>{l('opentimes', lang)}</p>
                <p>{l('closedtimes', lang)}</p>
                <p>{l('holidaytimes', lang)}</p>
              </div>

              <div className="col-md-3 footer-column">
                <h4>{l('Your account', lang)}</h4>
                <PageLink className="d-block" to={t(page('my-account'), 'path', lang)}>
                  {t(page('my-account'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('my-addresses'), 'path', lang)}>
                  {t(page('my-addresses'), 'title', lang)}
                </PageLink>
                <PageLink className="d-block" to={t(page('my-orders'), 'path', lang)}>
                  {t(page('my-orders'), 'title', lang)}
                </PageLink>

                <h4 className="mt-5">{l('title-social-media-follow-us', lang)}</h4>
                {process.env.GATSBY_FACEBOOK ? (
                  <a href={process.env.GATSBY_FACEBOOK} target="_blank" rel="noreferrer" className="mx-1">
                    <i className="fab fa-2x fa-facebook-square"></i>
                  </a>
                ) : ''}
                {process.env.GATSBY_INSTAGRAM ? (
                  <a href={process.env.GATSBY_INSTAGRAM} target="_blank" rel="noreferrer" className="mx-1">
                    <i className="fab fa-2x fa-instagram-square"></i>
                  </a>
                ) : ''}
              </div>
            </div>

            <div className="row footer-row">
              <div className="col-md-6 footer-column email">
                <NewsletterSubscriptionForm lang={lang} />
              </div>
              <div className="col-md-6 footer-column text-center">
                <div className="row mt-4">
                  <div className="col-12">
                    <EmotaLogo />
                    <SafeshopNomineeLogo />
                    <a href="https://label.safeshops.be/nl/BVBA-Up-North_4001795" title="Webshop Trustmark and customer reviews" target="_blank">
                      <SafeshopLogo />
                    </a>
                  </div>
                  <div className="col-12">
                    <PaypalLogo />
                    <MastercardLogo />
                    <VisaLogo />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12 text-center">
                &copy; {dateFormat(new Date(), "yyyy")} {process.env.GATSBY_SITENAME}
              </div>
            </div>

          </div>
        </section>
      )}
    />
  )
}

PageFooter.propTypes = {
  lang: PropTypes.object
}

export default PageFooter
